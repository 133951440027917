import { Box, Heading, Text, Button } from "grommet";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
import React from "react";
const StyledHashLink = styled(HashLink)`
  margin: 3rem auto;
`;

const AdDescription = () => {
  return (
    <Box>
      <Heading color="secondary" level="3">
        Słoneczna Panorama w Sułkowie
      </Heading>
      <Text size="small">
        Słoneczna Panorama to przytulne osiedle domów jednorodzinnych w miejscowości Sułków nieopodal Wieliczki.
        Inwestycja znajduje się w zacisznym i urokliwym miejscu, które jednocześnie zapewnia bliskość infrastruktury
        komunikacyjnej. Oferujemy wykończone w wysokim standardzie domy jednorodzinne o charakterze podmiejskim.
      </Text>
      <StyledHashLink to="/investment#main">
        <Button primary label="Przejdź do strony inwestycji" color="secondary" />
      </StyledHashLink>
    </Box>
  );
};

export default AdDescription;
